//
// general.scss
//

html {
  position: relative;
  min-height: 100%;
}

body {
  overflow-x: hidden;
}

.ant-select-dropdown {
  z-index: 99999 !important;
}

.width-700 {
  max-width: 700px;
}

.error-message {
  color: #ff4d4f;
}

/* Contact Us Container */
.contact-container {
  min-height: 100vh;
  background-color: #f3f4f6;
  padding: 3rem 1rem;
}

.contact-wrapper {
  max-width: 1200px;
  margin: 0 auto;
}

.contact-card {
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.contact-content {
  display: flex;
  flex-direction: column;
}

/* Image Section */
.image-section {
  position: relative;
  height: 300px;
}

.image-section img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-overlay {
  position: absolute;
  inset: 0;
  background: linear-gradient(135deg, rgba(79, 70, 229, 0.4) 0%, rgba(147, 51, 234, 0.4) 100%);
  padding: 2rem;
  color: white;
}

.image-overlay h2 {
  font-size: 1.875rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.image-overlay p {
  font-size: 1.125rem;
}

/* Form Section */
.form-section {
  padding: 2rem;
}

.form-container {
  max-width: 28rem;
  margin: 0 auto;
}

.form-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #1f2937;
  margin-bottom: 2rem;
}

.success-message {
  text-align: center;
  padding: 1rem;
  background-color: #d1fae5;
  color: #047857;
  border-radius: 0.375rem;
  margin-bottom: 1rem;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-label {
  display: block;
  font-size: 0.875rem;
  font-weight: 500;
  color: #374151;
  margin-bottom: 0.5rem;
}

.form-input,
.form-textarea {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #d1d5db;
  border-radius: 0.375rem;
  font-size: 1rem;
}

.form-input:focus,
.form-textarea:focus {
  outline: none;
  border-color: #6366f1;
  box-shadow: 0 0 0 3px rgba(99, 102, 241, 0.2);
}

.submit-button {
  width: 100%;
  padding: 0.75rem 1rem;
  background-color: #4f46e5;
  color: white;
  border: none;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.submit-button:hover {
  background-color: #4338ca;
}

.submit-button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(79, 70, 229, 0.4);
}

/* Media Query for larger screens */
@media (min-width: 768px) {
  .contact-content {
    flex-direction: row;
  }

  .image-section,
  .form-section {
    width: 50%;
  }

  .image-section {
    height: auto;
  }
}
